import * as React from "react"
import { Link, graphql } from "gatsby"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from "../components/layout"
import Seo from "../components/seo"

const HomeContent = () => (
  <Container>
      <Row>
        <h1>Hi</h1>
      </Row>

      <Row>
        <p>There really is not much to see here yet</p>
      </Row>

      <Row>
        <p>Go and follow me elsewhere, there's probably a bit more to see there.</p>
      </Row>
  </Container>
)

const HomeIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Home" />
        <HomeContent />
      </Layout>
  )
}

export default HomeIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
